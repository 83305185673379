import React from 'react';

const UxUiInteractionDesign = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1777 29.6728C16.9746 29.6728 16.7798 29.5952 16.6362 29.457C16.4925 29.3189 16.4118 29.1316 16.4118 28.9362V9.60661C16.3661 9.13706 16.4231 8.66349 16.5786 8.21623C16.7341 7.76897 16.9852 7.35795 17.3153 7.00929C17.6455 6.66063 18.0471 6.38212 18.4957 6.19153C18.9442 6.00095 19.4295 5.90234 19.9198 5.90234C20.4102 5.90234 20.8955 6.00095 21.344 6.19153C21.7925 6.38212 22.1941 6.66063 22.5243 7.00929C22.8545 7.35795 23.1056 7.76897 23.2611 8.21623C23.4166 8.66349 23.4736 9.13706 23.4279 9.60661V21.835C23.4279 22.0304 23.3471 22.2177 23.2035 22.3558C23.0598 22.494 22.8651 22.5716 22.6619 22.5716C22.4588 22.5716 22.264 22.494 22.1204 22.3558C21.9768 22.2177 21.896 22.0304 21.896 21.835V9.60661C21.931 9.33717 21.906 9.06376 21.8227 8.80432C21.7394 8.54489 21.5997 8.30539 21.4128 8.10185C21.2259 7.89831 20.9962 7.73529 20.7389 7.62364C20.4815 7.512 20.2022 7.45423 19.9198 7.45423C19.6375 7.45423 19.3581 7.512 19.1008 7.62364C18.8434 7.73529 18.6137 7.89831 18.4269 8.10185C18.24 8.30539 18.1003 8.54489 18.017 8.80432C17.9336 9.06376 17.9087 9.33717 17.9437 9.60661V28.9657C17.9357 29.1559 17.8516 29.3358 17.7088 29.4676C17.566 29.5995 17.3757 29.673 17.1777 29.6728Z"
      fill="#475467"
    />
    <path
      d="M28.1766 26.6668C27.9735 26.6668 27.7787 26.5894 27.635 26.4512C27.4914 26.3131 27.4107 26.1256 27.4107 25.9302V22.9836C27.4109 21.8999 27.0101 20.8514 26.2804 20.0255C25.5508 19.1996 24.5397 18.6502 23.4276 18.4754V26.0187C23.4276 26.2141 23.3469 26.4014 23.2032 26.5395C23.0596 26.6777 22.8648 26.7553 22.6617 26.7553C22.4586 26.7553 22.2638 26.6777 22.1201 26.5395C21.9765 26.4014 21.8958 26.2141 21.8958 26.0187V17.7093C21.8958 17.5139 21.9765 17.3266 22.1201 17.1885C22.2638 17.0503 22.4586 16.9727 22.6617 16.9727C24.3007 17.0111 25.8588 17.6646 27.0037 18.7932C28.1487 19.9219 28.7897 21.4363 28.7892 23.0131V25.9597C28.7857 26.127 28.7233 26.2881 28.6119 26.4167C28.5005 26.5452 28.347 26.6334 28.1766 26.6668Z"
      fill="#475467"
    />
    <path
      d="M33.6608 24.9558C33.4577 24.9558 33.2629 24.8781 33.1193 24.74C32.9756 24.6018 32.8949 24.4145 32.8949 24.2192C32.8949 23.3283 32.5266 22.4739 31.8716 21.8439C31.2166 21.214 30.3281 20.86 29.4018 20.86H28.9425V24.2192C28.9425 24.4145 28.8618 24.6018 28.7181 24.74C28.5745 24.8781 28.3797 24.9558 28.1766 24.9558C27.9734 24.9558 27.7787 24.8781 27.635 24.74C27.4914 24.6018 27.4106 24.4145 27.4106 24.2192V20.1234C27.4106 19.928 27.4914 19.7407 27.635 19.6025C27.7787 19.4644 27.9734 19.3867 28.1766 19.3867H29.4018C30.7344 19.3867 32.0124 19.8958 32.9547 20.8021C33.897 21.7083 34.4268 22.9375 34.4268 24.2192C34.4268 24.4145 34.346 24.6018 34.2024 24.74C34.0587 24.8781 33.864 24.9558 33.6608 24.9558Z"
      fill="#475467"
    />
    <path
      d="M39.1749 26.6681C38.9717 26.6681 38.777 26.5906 38.6333 26.4525C38.4897 26.3143 38.4089 26.1268 38.4089 25.9315C38.4089 25.0406 38.0414 24.1862 37.3864 23.5562C36.7314 22.9263 35.8429 22.5725 34.9166 22.5725H34.4267V26.0199C34.4267 26.2153 34.3459 26.4026 34.2023 26.5408C34.0586 26.6789 33.8639 26.7566 33.6607 26.7566C33.4576 26.7566 33.2628 26.6789 33.1192 26.5408C32.9755 26.4026 32.8948 26.2153 32.8948 26.0199V21.9241C32.8948 21.7288 32.9755 21.5415 33.1192 21.4033C33.2628 21.2652 33.4576 21.1875 33.6607 21.1875H34.9166C36.2467 21.1952 37.52 21.7069 38.4605 22.6115C39.4011 23.5161 39.9328 24.7407 39.9408 26.0199C39.9182 26.1996 39.8278 26.3651 39.6865 26.4847C39.5452 26.6042 39.363 26.6694 39.1749 26.6681Z"
      fill="#475467"
    />
    <path
      d="M35.7439 47.9991H16.7488C16.5652 48.0014 16.3868 47.94 16.2462 47.8264C16.1056 47.7129 16.0122 47.5546 15.9829 47.3802C14.822 41.1418 12.5081 35.154 9.15078 29.7008C8.52332 29.0759 8.17172 28.2417 8.17017 27.373C8.17817 26.4767 8.55159 25.6192 9.21061 24.9854C9.86964 24.3516 10.7612 23.9921 11.6932 23.9844C12.1554 23.9836 12.6133 24.0718 13.0396 24.2439C13.4658 24.416 13.8518 24.6684 14.175 24.9863L17.698 28.3748C17.7858 28.4381 17.8587 28.5188 17.9112 28.6114C17.9637 28.7041 17.9947 28.8067 18.0024 28.912C18.0102 29.0172 17.9944 29.1228 17.9561 29.2216C17.9177 29.3205 17.8579 29.4103 17.7803 29.4849C17.7027 29.5596 17.6093 29.6173 17.5065 29.6542C17.4037 29.6911 17.2936 29.7064 17.1841 29.699C17.0747 29.6915 16.9686 29.6614 16.8722 29.6108C16.7759 29.5603 16.692 29.4906 16.6261 29.4062L13.1024 26.0175C12.7214 25.6715 12.2172 25.4787 11.6932 25.4787C11.1691 25.4787 10.665 25.6715 10.284 26.0175C9.91346 26.379 9.70473 26.8655 9.70204 27.373C9.71101 27.8793 9.91882 28.3635 10.284 28.7283C13.6539 34.1905 16.0172 40.1717 17.2694 46.4078H35.0393C35.5908 44.522 37.4292 38.1574 38.3177 34.7983C38.8879 31.8772 38.8879 28.8796 38.3177 25.9585C38.2812 25.7636 38.3247 25.5626 38.4389 25.3978C38.5532 25.233 38.7292 25.1172 38.9303 25.0746C39.1293 25.0402 39.3339 25.0827 39.5003 25.1931C39.6667 25.3035 39.7817 25.4728 39.8189 25.664C40.4438 28.7981 40.4438 32.0177 39.8189 35.1519C38.7773 39.1003 36.4178 47.2624 36.3872 47.3508C36.369 47.5118 36.2963 47.6627 36.18 47.7799C36.0637 47.8971 35.9103 47.9741 35.7439 47.9991Z"
      fill="#475467"
    />
    <path
      d="M14.2671 10.3444H10.376C10.1729 10.3444 9.97814 10.2667 9.8345 10.1286C9.69086 9.99042 9.61011 9.80311 9.61011 9.60774C9.61011 9.41237 9.69086 9.22506 9.8345 9.08691C9.97814 8.94876 10.1729 8.87109 10.376 8.87109H14.1444C14.3475 8.87109 14.5423 8.94876 14.6859 9.08691C14.8296 9.22506 14.9103 9.41237 14.9103 9.60774C14.9103 9.80311 14.8296 9.99042 14.6859 10.1286C14.5423 10.2667 14.3475 10.3444 14.1444 10.3444H14.2671Z"
      fill="#475467"
    />
    <path
      d="M15.9216 6.60059C15.7187 6.59446 15.5235 6.52182 15.3696 6.39449L12.7045 3.8308C12.6313 3.76466 12.5732 3.68479 12.5332 3.5961C12.4933 3.50741 12.4727 3.41182 12.4727 3.31518C12.4727 3.21854 12.4933 3.12295 12.5332 3.03426C12.5732 2.94557 12.6313 2.8657 12.7045 2.79956C12.776 2.72827 12.8618 2.67158 12.9566 2.63285C13.0514 2.59411 13.1528 2.57422 13.2558 2.57422C13.3588 2.57422 13.461 2.59411 13.5557 2.63285C13.6505 2.67158 13.7364 2.72827 13.8078 2.79956L16.4422 5.33358C16.5163 5.4023 16.5755 5.4847 16.6157 5.57583C16.656 5.66696 16.6763 5.76493 16.6763 5.86394C16.6763 5.96296 16.656 6.06111 16.6157 6.15224C16.5755 6.24337 16.5163 6.32577 16.4422 6.39449C16.2977 6.51766 16.1144 6.59018 15.9216 6.60059Z"
      fill="#475467"
    />
    <path
      d="M19.8126 5.06821C19.6094 5.06821 19.4147 4.99055 19.271 4.8524C19.1274 4.71425 19.0466 4.52694 19.0466 4.33157V0.736645C19.0466 0.541275 19.1274 0.353962 19.271 0.215814C19.4147 0.0776663 19.6094 0 19.8126 0C20.0157 0 20.2105 0.0776663 20.3541 0.215814C20.4978 0.353962 20.5785 0.541275 20.5785 0.736645V4.33157C20.5785 4.52694 20.4978 4.71425 20.3541 4.8524C20.2105 4.99055 20.0157 5.06821 19.8126 5.06821Z"
      fill="#475467"
    />
    <path
      d="M23.7034 6.65936C23.6065 6.66128 23.5103 6.64396 23.4207 6.60847C23.3311 6.57298 23.2495 6.52006 23.1821 6.45308C23.1088 6.38695 23.0507 6.30707 23.0108 6.21838C22.9709 6.1297 22.9502 6.03411 22.9502 5.93747C22.9502 5.84082 22.9709 5.74524 23.0108 5.65655C23.0507 5.56786 23.1088 5.48799 23.1821 5.42185L25.8172 2.85834C25.8887 2.78704 25.9745 2.73036 26.0693 2.69162C26.1641 2.65289 26.2655 2.63281 26.3685 2.63281C26.4714 2.63281 26.5737 2.65289 26.6684 2.69162C26.7632 2.73036 26.8483 2.78704 26.9198 2.85834C27.0532 2.99939 27.1277 3.18323 27.1277 3.37395C27.1277 3.56468 27.0532 3.74851 26.9198 3.88957L24.2547 6.45308C24.1826 6.52232 24.096 6.57638 24.0011 6.61189C23.9062 6.64739 23.8052 6.6636 23.7034 6.65936Z"
      fill="#475467"
    />
    <path
      d="M29.0651 10.4303H25.2967C25.0936 10.4303 24.8981 10.3527 24.7544 10.2145C24.6108 10.0764 24.5308 9.88905 24.5308 9.69368C24.5308 9.49831 24.6108 9.31081 24.7544 9.17267C24.8981 9.03452 25.0936 8.95703 25.2967 8.95703H29.0651C29.2682 8.95703 29.463 9.03452 29.6066 9.17267C29.7502 9.31081 29.831 9.49831 29.831 9.69368C29.831 9.88905 29.7502 10.0764 29.6066 10.2145C29.463 10.3527 29.2682 10.4303 29.0651 10.4303Z"
      fill="#475467"
    />
  </svg>
);

export default UxUiInteractionDesign;
